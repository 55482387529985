import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader} from "shards-react";
import PageTitle from "../components/common/PageTitle";
// import testimage from './shards-dashboard-react-master/src/images/content-management/testimage.jpg'

const About = () => (
  <Container className="main-content-container px-5 pb-3">
    <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Welcome"
          className="text-sm-left"
        />
    </Row>
    <Row noGutters className="px-0">
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h5 className="m-0">About the Group</h5>
          </CardHeader>
          <CardBody className="p-0">
            <div className="blog-comments__content">
              <div className="blog-comments__meta text-mutes">
              <p className="m-2 my-1 mb-2 text-muted"> The Round Table Floorcovering Credit Group resulted from the merger of the Round Table Group, based in New York City, and the Atlanta Floor Covering Credit Group, based in Atlanta, Georgia. The merger occurred in 1985. The Round Table Group can trace its existence to 1917, giving the group a history that goes back over 85 years. The Round Table Group consisted of factors headquartered in New York City. Names such as Manufacturer's Hanover, Irving Financial, CIT, Bank of New York, Barclays lead the group during the time of the early period of the north Georgia carpet boom. An understanding of the Group would not be complete without understanding the history of the carpet industry in Georgia.
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="px-0">
      <Col>
        <Card small  className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h5 className="m-0">A Short History of the Georgia Carpet Industry</h5>
          </CardHeader>
          <CardBody className="p-0">
            <div className="blog-comments__content">
              <div className="blog-comments__meta text-mutes">
              <p className="m-2 my-1 mb-2 text-muted"> Chennile beadspreads are responsible for making carpet king in North Georgia. The following is taken from the Carpet and Rug Institue:
              </p>
              <p className="m-2 my-1 mb-2 text-muted">Through the late 1800s, Dalton, Georgia, struggled with cotton mills and steel manufacturing works to forge a small town in the north Georgia hills. Northwest Georgia, with its hard-packed clay, poor farmland, and rolling hills was among the last areas of Georgia settled. Rich in a heritage of Cherokee Indians and Civil War battles, that northern corner of the state was rugged and spawned people who were independent and self-sufficient. Those were the people who brought forth and nurtured the tufted textile industry. The industry's infancy was in Dalton; it has gone through intense growth in Dalton; and it has now matured in and around Dalton. The carpet industry's impact is great on this region, this state, and the nation; and the story of its growth is unique.</p>
              <CardHeader className="border-bottom">
                <h5 className="m-0">The Beginnings</h5>
              </CardHeader>
              <p className="m-2 my-1 mb-2 text-muted">The industry began in a simple way, around the turn of the century. A young, Dalton woman, Catherine Evans Whitener, recreated a bedspread in a hand-crafted pattern she had seen, for a wedding gift. Copying a quilt pattern, she sewed thick cotton yarns with a running stitch into unbleached muslin, clipped the ends of the yarn so they would fluff out, and finally, washed the spread in hot water to hold the yarns in by shrinking the fabric. Interest grew in young Catherine's bedspreads, and in 1900, she made the first sale of a spread for $2.50. Demand became so great for the spreads that by the 1930s, local women, who were real entrepreneurs, had "haulers", who would take the stamped sheeting and yarns to front porch workers. Often, entire families worked to hand tuft the spreads for 10 to 25 cents per spread. The local term for the sewing process was "turfin" for the nearly 10,000 area cottage tufters -- men, women, and children. Bedspread income was instrumental in helping many area families survive the depression.</p>
              <p className="m-2 my-1 mb-2 text-muted">As an example of the spirit of these early entrepreneurial women, Mrs. J. T. Bates stated that she simply "shipped 15 spreads to John Wannamaker's department store in New York. On a piece of plain tablet paper I made out a bill for $98.15 and put it in with the spreads. Although there had been no previous contact whatsoever with the store, Wannamakers sent us a check for $98.15." Chenille bedspreads became amazingly popular all over the country and provided a new name for Dalton: The Bedspread Capital of the World.</p>
              <CardHeader className="border-bottom">
                <h5 className="m-0">Mechanization -- the 1930s</h5>
              </CardHeader>
              <p className="m-2 my-1 mb-2 text-muted">Buyer competition, which tended to lower the prices, the change in the minimum wage laws, and development of machine-produced spreads soon made the hand-crafted spreads too expensive. Gradually the industry began to pull the workers from surrounding hillsides and small towns into mills in Dalton, beginning the rapid growth of the mechanized tufting industry.</p>
              <p className="m-2 my-1 mb-2 text-muted">In the 1930s, as a result of the demand for more bedspreads, the first mechanized tufting machine, attributed to Glen Looper Foundry of Dalton, was developed. Looper modified the single needle commercial Singer so that it would tuft the thick yarn into unbleached muslin without tearing the fabric and an attached knife would cut the loop. Machines quickly developed into four, then eight, twenty-four, and more needles to make the parallel rows of tufting known as "chenille." By 1941, all but about one percent of tufted bedspreads were machine made. Mats and rugs were created with the same process, using cotton yarns and fabric. Volume increased rapidly after World War II, because people were hungry for color and beauty. To show the extent of growth, 30,000 bales of cotton were consumed in 1946 by the industry. By 1950, approximately 500,000 bales were used, and the industry was the third largest consumer of cotton grown in Georgia in 1952.</p>
              <p className="m-2 my-1 mb-2 text-muted">Sales were created by correspondence or by taking spreads to department stores, but by far the most famous and enjoyable way to buy a spread was on "Bedspread Alley", U. S. Highway 41 between Dalton and Cartersville. This stretch of the major north-south highway got its nickname because of the bedspreads the tufters hung on clotheslines to dry in the breeze and sun.</p>
              <p className="m-2 my-1 mb-2 text-muted">The salesmen and tourists enjoyed seeing the colorful, gaudy spreads and enjoyed the novelty of buying them "off the line". The most popular pattern to the travelers, outselling all others 12 to 1 was the Peacock -- feathered birds facing each other and spreading tails over the breadth of the spread. This "Bedspread Alley" phenomenon lasted into the 70s, and even now a few spreads can be seen on lines just south of Dalton.</p>
              <p className="m-2 my-1 mb-2 text-muted">As the number of tufted products produced annually went into the millions, the job of supplying the industry became equally important. Yarn, sheeting, duck mills, and agents were established in the area, with their entire output going to the industry; and larger mills elsewhere vied for the growing business. Machine shops were established to manufacture the thousands of single and multi-needle machines needed, as well as to design improvements aimed at making even more beautiful and better spreads, bathroom sets, robes, beach wear, and rugs. Dye plants for yarn were built. Laundries were erected for finishing the spreads. Printing shops were established to supply the millions of tags and labels needed. Box factories turned out cartons for shipping. Moving these spreads to market was big volume for rail and motor freight lines.</p>
              <p className="m-2 my-1 mb-2 text-muted">Machinery was developed for making chenille rugs and was widened, creating larger rugs and broadloom carpet. At the same time, machinery was changing, developments of new fibers accelerated the growth of broadloom carpet.</p>
              <CardHeader className="border-bottom">
                <h5 className="m-0">Synthetic Fibers are Introduced</h5>
              </CardHeader>
              <p className="m-2 my-1 mb-2 text-muted">Until about 1954, cotton was virtually the only fiber used in tufted products. Wool and manmade fibers -- polyester, nylon, rayon, and acrylics -- were gradually introduced by textile men in Dalton. Nylon was first introduced in 1947 and grew steadily to dominate the market. Polyester was first used in 1965 and was followed soon by polypropylene (olefin). Most manufacturers will agree that the single most important development in the industry was the introduction of bulk continuous filament nylon yarns. These yarns provided a luxurious quality, durable carpet, similar to wool, that was more economical to produce. Therefore, a durable, luxury product was offered to the consumer for less money.</p>
              <p className="m-2 my-1 mb-2 text-muted">In 1950, only ten percent of all carpet and rug products were tufted, and ninety percent were woven. However, about 1950, it was as if someone had opened a magic trunk. Out of that trunk came man-made fibers, new spinning techniques, new dye equipment, printing processes, tufting equipment, and backing for different end uses. Today, tufted products are more than 90 percent of the total, followed by less than 2 percent that are woven, and 6.7 percent for all other methods, such as knitted, braided, hooked, or needlepunched. By 1951, the tufting industry was a $133 million per year business made up primarily of bedspreads, carpet, and rugs, with carpet accounting for $19 million. The industry broke the billion dollar mark in 1963. Through the years, the Dalton area has continued to be the center of the tufted carpet industry, and today, the area produces more than 70 percent of the total output of the world-wide industry of over $9 billion. Dalton is now known as the "Carpet Capital of the World".</p>
              <p className="m-2 my-1 mb-2 text-muted">---Carpet & Rug Institute</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>

  
  );

export default About;
