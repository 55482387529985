import React from "react";
import { Form, FormInput, FormGroup, Button, ListGroup, ListGroupItem, Row, Col, Container, Card } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Auth from "../flux/services/AuthService";
import { Redirect } from "react-router-dom";


class Login extends React.Component {
    
    constructor(props) {
      super();
      this.state = {
        user: '',
        password: '',
        loggedin: false
      };
      this.handleChange = this.handleChange.bind(this);
      
    }

    handleChange(event) {
       //alert('event triggered ' + event.target.id);
        if (event.target.id === "#username")
            this.setState({user: event.target.value});
        if (event.target.id === "#password")
            this.setState({password: event.target.value});
    }

  
    async login(e) {
      
      e.preventDefault();
      let loginResult = await Auth.login(this.state.user, this.state.password)
        .catch(function(err) {
          alert("There's an error logging in");
          console.log("Error logging in", err);
        })
      if(!!loginResult)
       this.setState({loggedin: loginResult});
      
      return;
    }

  
    render() {
      //alert ("isLoggedin: " + loginStore.isLoggedIn());
      if (this.state.loggedin === true)
      {
        return (
          <Redirect to='/ViewDashboard' />
        )
      }
      return (

    <Container fluid className="main-content-container px-5 pb-3">
        <Row noGutters className="page-header py-4">
            <PageTitle
            sm="4"
            title="Login"
            subtitle="Customer Access"
            className="text-sm-left"
            />
        </Row>

            <Row className="px-0">
              <Col lg="5">
                <Card small className="px-3 py-3">
                    <ListGroup flush>
                    <ListGroupItem className="p-3">
                    <Row>
                      
                        <Form role="form">
                            <Col md="10">
                                <FormGroup>
                                <label htmlFor="#username">Username</label>
                               <FormInput id="#username" placeholder="Username" value={this.state.user} onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup>
                                <label htmlFor="#password">Password</label>
                                <FormInput type="password" id="#password" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup>
                                    <Button type="submit"  onClick={this.login.bind(this)}>Submit</Button>
                                </FormGroup>
                            </Col> 
                          </Form>
                      
                    </Row>
                    </ListGroupItem>
                    </ListGroup>
                </Card>
              </Col> 
          </Row>
    </Container>
        /*<form onSubmit={this.handleSubmit}>
          <label>
            Name:
            <input type="text" value={this.state.value} onChange={this.handleChange} />
          </label>
          <input type="submit" value="Submit" />
        </form>*/
      );
    }
  }

  export default Login;
/*

export default function Login() {
    return (
      <Form>
        <FormGroup>
          <label htmlFor="#username">Username</label>
          <FormInput id="#username" placeholder="Username" />
        </FormGroup>
        <FormGroup>
          <label htmlFor="#password">Password</label>
          <FormInput type="password" id="#password" placeholder="Password" />
        </FormGroup>
      </Form>
    );
  }
*/

//ReactMixin(Login.prototype, React.LinkedStateMixin);