import React,{ useState, useEffect} from "react";
import { Container, Button, Card, CardHeader, Row } from "shards-react";
import PaymentExperienceService from "../../../flux/services/PaymentExperienceService";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";
import formatDollar from "../../../utils/formatDollar";

 
export default function PaymentExperience (props)
{
  //const [view, setView] = useContext(useViewContext());
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getPaymentExperience(accountId)
    {

      try {
        // set loading to true before calling API
        const data = await PaymentExperienceService.getPaymentExperience(accountId);
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
    getPaymentExperience(props.accountId);
  
    }, []);
  
  const columns = [
      {
        name: 'Member Id',
        selector: data => data.member_id.toString(),
        sortable: true,
        width: '10%'
      },
      {
        name: 'Date Reported',
        selector: data => data.extract_date_id,
        cell: data => data.extract_date_id.toString().substring(4,6) + '/' + data.extract_date_id.toString().substring(6,8) + '/' + data.extract_date_id.toString().substring(2,4),
        sortable: true,
        width: '10%'
      },
      {
        name: 'Terms',
        sortable: true,
        selector: data => (data.terms == 'CBD' || data.terms == 'CIA' || data.terms == 'COD')?data.terms:"",
        width: '8%'
      },
      {
        name: 'Last Sales',
        sortable: true,
        selector: data => data.date_last_sale_id.toString().substring(4,6) +  '/' + data.date_last_sale_id.toString().substring(4,2),
        width: '8%'
      },
      {
        name: 'Pmt History',
        selector: data => paymentHistory(!data.payment_history?"":data.payment_history, data.number_days_slow),
        width: '8%'
      },
      {
        name: (<div class="w-100 text-end">High Credit</div>),
        selector: data => data.recent_high_credit,
        cell: data => (<div class="w-100 text-end text-nowrap">{formatDollar(data.recent_high_credit)}</div>),
        sortable: true,
        width: '8%'
      },
      {
        id: data => data.account_balance,
        name: (<div class="w-100 text-end">Balance</div>),
        selector: data => Number(data.account_balance),
        cell: data => (<div class="w-100 text-end text-nowrap">{formatDollar(data.account_balance)}</div>),
        sortable: true,
        width: '9%'
      },
      {
        name: 'Current',
        selector: data => data.pct_current,
        cell: data => data.pct_current==0?"":data.pct_current + '%',
        sortable: true,
        width: '8%'
      },
      {
        name: '1-30',
        selector: data => data.pct_one_day_past_due==0?"":data.pct_one_day_past_due + '%' ,
        width: '8%'
      },
      {
        name: '31-60',
        selector: data => data.pct_thirty_day_past_due==0?"":data.pct_thirty_day_past_due + '%' ,
        width: '8%'
      }
      ,
      {
        name: '61-90',
        selector: data => data.pct_sixty_day_past_due==0?"":data.pct_sixty_day_past_due + '%',
        width: '8%'
      },
      {
        name: '91+',
        selector: data => data.pct_ninety_day_past_due==0?"":data.pct_ninety_day_past_due + '%', 
        width: '7%'
      }
  ]

  function paymentHistory(payment_history, number_days_slow)
  {
    let paymentHistory = ''
    if (payment_history == "A/S" || payment_history == "P/S" || payment_history == "D/S" || payment_history == "Slo")
      paymentHistory = payment_history + number_days_slow;
    else 
      paymentHistory = payment_history;

      return paymentHistory;
  }
  

  const Rows = (props) => {
    

    const {profile, member_id,date_reported,date_last_sale, extract_date, terms,recent_high_credit,account_balance,pct_current,pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due, number_days_slow, payment_history} = props
    let dateReported = extract_date.toString().substring(4,6) + '/' + extract_date.toString().substring(6,8) + '/' + extract_date.toString().substring(2,4);
    let lastSale = date_last_sale.toString().substring(4,6) + '/' + date_last_sale.toString().substring(2,4);
    let memberIdDisplay = member_id;
   
    //alert(profile)
    if (profile == 'alacarte')
      memberIdDisplay = ''

    //alert(recent_high_credit);
    return ( <tr>
      <td>{memberIdDisplay}</td>
      <td>{dateReported}</td>
      <td>{terms}</td>
      <td>{lastSale}</td>
      <td>{paymentHistory(payment_history, number_days_slow)}</td>
      <td>{formatDollar(recent_high_credit)}</td>
      <td>{formatDollar(account_balance)}</td>
      <td>{pct_current==0?"":pct_current + '%'}</td>
      <td>{pct_one_day_past_due==0?"":pct_one_day_past_due + '%'}</td>
      <td>{pct_thirty_day_past_due==0?"":pct_thirty_day_past_due + '%'}</td>
      <td>{pct_sixty_day_past_due==0?"":pct_sixty_day_past_due + '%'}</td>
      <td>{pct_ninety_day_past_due==0?"":pct_ninety_day_past_due + '%'}</td>
    </tr>
    )
  }
 


  const Table = (props) => {
    
    
    //let payment_history = !data[0].attribute?"":data.attribute.attribute_description;
    
    //payment_history={data.attribute.attribute_description}
  
    return ( <Row className="px-5"><table id="paymentExperience" className="table table-sm">
              <thead className="table-light">
                <tr>
                
                <th scope="col" >Member Id</th>
                <th scope="col">Date Reported</th>
                <th scope="col">Terms</th>
                <th scope="col">Last Sale</th>
                <th scope="col">Pmt History</th>
                <th scope="col">High Credit</th>
                <th scope="col">Balance</th>
                <th scope="col">Current</th>
                <th scope="col">1-30</th>
                <th scope="col">31-60</th>
                <th scope="col">61-90</th>
                <th scope="col">90+</th>
                </tr>
              </thead>
            <tbody> 
              
              {data.map((data) => (
                <Rows key={data.credit_info_id}  member_id={data.member_id} date_reported={data.load_date_id} date_last_sale={data.date_last_sale_id} extract_date={data.extract_date_id} terms={data.terms} recent_high_credit={data.recent_high_credit} account_balance={data.account_balance} pct_current={data.pct_current}
                      pct_one_day_past_due={data.pct_one_day_past_due} pct_thirty_day_past_due={data.pct_thirty_day_past_due} pct_sixty_day_past_due={data.pct_sixty_day_past_due}
                      pct_ninety_day_past_due={data.pct_ninety_day_past_due} number_days_slow={data.number_days_slow} payment_history={!data.payment_history?"":data.payment_history} profile={props.data.profile}  /> 
              ))}

            </tbody>
            </table>
            </Row>)
  }

  if (!data) return (
    <span>Data not available</span>
  );
  //alert(JSON.stringify(props));
  return (
    

      <Container>
      <CardHeader className="border-bottom">
        <h5>
          Payment Experience
        </h5>
      </CardHeader>
      <Row className="px-2">
      <DataTable customStyles={tableCustomStyles} columns={columns}
        data={data}
        ></DataTable>
        </Row>
      </Container>

  )
}

