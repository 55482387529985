import React,{ useState, useEffect} from "react";
import { Container, Card, CardHeader, Row, Col, FormSelect, Modal, ModalBody, ModalHeader, Button, CardBody, ListGroup, Form, FormGroup, FormInput } from "shards-react";
import { ProtectedRoute } from "../../../ProtectedRoute";
import AdminService from "../../../flux/services/AdminService";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";

 
export default function Users (props)
{
  //const [view, setView] = useContext(useViewContext());
  let [state, setState]= useState({
    open: false,
    userName: "",
    name: "",
    password: "",
    confirmPassword: "",
    memberId: "",
    email: "",
    profile: "member",
    userInfoId: 0,
    internalId: "",
    action: "new",
    UpdatePassword: ""
  });
  let [memberData, setMemberData] = useState(null);
  let [data, setData] = useState(null);
  useEffect(() => {
    async function getUsers()
    {

      try {
        // set loading to true before calling API
        const data = await AdminService.getAllUsers();
        setData(data);
        const memberData = await AdminService.getAllMembers();
        setMemberData(memberData);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
        getUsers();
  
    }, []);

async function refreshUsers(){
    try {
        // set loading to true before calling API
        const data = await AdminService.getAllUsers();
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
}

async function UpdateUserPassword(userInfoId){
  let result = await AdminService.getUserById(userInfoId)
  setState( prevState =>
    (
        {
            ...prevState,
            userName: result[0].user_name,
            name: result[0].member.name,
            memberId: result[0].member_id,
            email: result[0].email,
            profile: result[0].profile,
            userInfoId: userInfoId,
            internalId: result[0].internal_id,
            action: "update"
        }
    )
    );
  
  
  toggle();
}

async function handleChange(event) {

        let newValue = event.target.value;
        let memberGroup = state.memberGroup;
       // alert("newValue " + newValue)
        switch(event.target.id)
        {
            case "#UserName":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        userName: newValue
                    }
                )
                );
              break;
            case "#Email":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        email: newValue
                    }
                )
                );
              break;
            case "#Profile":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        profile: newValue
                    }
                )
                );
              break;
            case "#Member":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        memberId: newValue
                    }
                )
                );
              break;
            case "#Password":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        password: newValue
                    }
                )
                );
              break;
            case "#ConfirmPassword":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        confirmPassword: newValue
                    }
                )
                );
              break;
            case "#UpdatePassword":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        UpdatePassword: newValue
                    }
                )
                );
              break;
            default:
                break;
        }


    }


    
    const columns = [
        {
          name: 'User Name',
          selector: data => data.user_name,
          sortable: true
        },
        {
          name: 'Email',
          selector: data => data.email,
          cell: data => data.email,
          sortable: true
        },
        {
          name: 'Member',
          sortable: true,
          cell: data => (<div className="w-90 text-end text-nowrap">{data.member_id + " - " + data.member.name}</div>),
          selector: data => data.member_id
          
        },
        {
          name: 'Profile',
          selector: data => data.profile,
          sortable: true
        },
        {
          name: 'Update',
          cell: data => (<button type="button" class="btn btn-link" onClick={() => UpdateUserPassword(data.user_info_id)} >Update</button>)
        }
    ]


  function addUserClicked(e){
    setState( prevState =>
      ({
      ...prevState,
      action: "new"
      }
      ) 
      );
    toggle();

  } 
  /** Handle opening and closing the Modal window */
  function toggle(e) {
   
    setState( prevState =>
      ({
      ...prevState,
      open: !state.open
      }
      ) 
      );
      
  }

  async function addUser(e) {
    
    if (state.action == "new")
    {
        if (state.userName == null || state.userName == '')
          alert('Username is missing')
        else if (state.email == null || state.email == '' )
          alert('Email is missing')
        else if (state.password == null || state.password == '')
          alert('Password is missing')
        else if (state.confirmPassword != state.password)
          alert('Password and Confirm Password do not Match')
        else
        {
          await AdminService.addUser(state);
          refreshUsers();
          toggle();
        }
    }
    else
    {
        if (state.UpdatePassword == "checked" && (state.password == null || state.password == ''))
          alert('Password is missing')
        else if (state.UpdatePassword == "checked" &&  (state.confirmPassword != state.password))
          alert('Password and Confirm Password do not Match')
        else
        {
         // alert(JSON.stringify(state))
          await AdminService.updateUser(state);
          refreshUsers();
          toggle();
        }

    }
  }

 const Members = (props) =>
  {
    
   

    return (
        <div>
        <label htmlFor="#Member">Member</label>
        <FormSelect className="mb-2 mr-1" id="#Member" onChange={handleChange.bind(this)} >
        {memberData.map((data) => (
                  <option selected={data.member_id == props.memberId?true:false} value={data.member_id}>{data.member_id + " - " + data.name}</option> 

              ))}
        </FormSelect> 
        </div>
    )
  }

  const UpdatePassword = (props) =>
    {
      
     
      if (props.action == "update" && state.UpdatePassword == "checked")
        return (
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" onChange={handleChange.bind(this)}  id="#UpdatePassword" checked/>
            <label class="form-check-label" for="#UpdatePassword">Update Password
            </label>
          </div>  
        )
      else if (props.action == "update" && state.UpdatePassword != "checked")
        return (
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="checked" onChange={handleChange.bind(this)}  id="#UpdatePassword"/>
            <label class="form-check-label" for="#UpdatePassword">Update Password
            </label>
          </div>  
        )

      return <div></div>
    }

  if (!data) return (
    <ProtectedRoute>
        <Container>
      
            <Card>
            <CardHeader className="border-bottom">
            <h5>
                User Managment
            </h5>
            </CardHeader>
            <span>Data not available</span>
            </Card>
        </Container>

    </ProtectedRoute>
    
  );

  return (
    
    <ProtectedRoute>
      <Container>
      
      <Card>
      <CardHeader className="border-bottom">
        <h5>
          User Managment
        </h5>
      </CardHeader>
      <CardBody>
        <Row className="px-4">
            <Col lg="11" className="py-3 px-4">
            <div className="d-flex justify-content-left bd-highlight">
              <Button size="sm" className="mb-2 mr-1" onClick={addUserClicked.bind(this)}>Add User</Button>
            </div>
            </Col>            
        </Row> 
        <DataTable customStyles={tableCustomStyles} columns={columns}
            data={data}
        ></DataTable>
      </CardBody>
      <Row>
      
        <Modal className="modal-md" open={state.open}  centered={true}>
          <ModalHeader>Add User</ModalHeader>
          <ModalBody>
            <Card className="mb-4">                     
              <CardBody className="p-1">
                  <Row noGutters className="page-header px-0 py-4">
                     <Col className="m-2">
                      <Row>

                        <Col>
                          <FormGroup>
                          <label htmlFor="#UserName">User Name</label>
                            <FormInput id="#UserName" placeholder="User Name" required value={state.userName} onChange={handleChange.bind(this)} />  
                          <label htmlFor="#Email">Email</label>
                              <FormInput id="#Email" placeholder="Email" required value={state.email} onChange={handleChange.bind(this)} />
                            <Members memberId={state.memberId} ></Members>   
                          <label htmlFor="#Profile">Profile</label>
                          <FormSelect className="mb-2 mr-1" id="#Profile" onChange={handleChange.bind(this)} >
                              <option selected={state.profile=="member"?true:false} value="member">Member</option>
                              <option selected={state.profile=="alacarte"?true:false} value="alacarte">A La Carte</option>
                              <option selected={state.profile=="admin"?true:false} value="admin">Admin</option>
                          </FormSelect>  
                          <UpdatePassword action={state.action} />
                          <label htmlFor="#Password">Password</label>
                              <FormInput type="password" id="#Password" placeholder="Password" required value={state.password} onChange={handleChange.bind(this)} />
                            <label htmlFor="#ConfirmPassword">Confirm Password</label>
                              <FormInput type="password" id="#ConfirmPassword" placeholder="Confirm Password" required value={state.confirmPassword} onChange={handleChange.bind(this)} />
                          </FormGroup>
                        </Col>
                        
                        <Row>
                          <div class="d-flex justify-content-center">
                                    
                                    <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={addUser.bind(this)}>Save</Button>
                                    <label>&nbsp;&nbsp;</label>
                                    <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={toggle}>Cancel</Button>
                                  
                            </div>
                        </Row>
                      </Row>
                      </Col>
                  </Row>
                </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        
      </Row>
      </Card>
      </Container>
      </ProtectedRoute>
  )
}

