import React,{ useState, useEffect} from "react";
import { Container, Card, CardHeader, Row, Col, FormSelect, Modal, ModalBody, ModalHeader, Button, CardBody, FormGroup, FormInput } from "shards-react";
import { ProtectedRoute } from "../../../ProtectedRoute";
import AdminService from "../../../flux/services/AdminService";

 
export default function Members (props)
{


  const stateList = [{id: "AL", name: "Alabama"},{id: "AK", name: "Alaska"},{id: "AK", name: "Alaska"},{id: "AZ", name: "Arizona"},{id: "AR", name: "Arkansas"},{id: "CA", name: "California"},{id: "CO", name: "Colorado"},{id: "CT", name: "Connecticut"},{id: "DE", name: "Delaware"},{id: "DC", name: "District Of Columbia"},{id: "FL", name: "Florida"},{id: "GA", name: "Georgia"},{id: "HI", name: "Hawaii"},{id: "ID", name: "Idaho"},{id: "IL", name: "Illinois"},{id: "IN", name: "Indiana"},{id: "IA", name: "Iowa"},{id: "KS", name: "Kansas"},{id: "KY", name: "Kentucky"},{id: "LA", name: "Louisiana"},{id: "ME", name: "Maine"},{id: "MD", name: "Maryland"},{id: "MA", name: "Massachusetts"},{id: "MI", name: "Michigan"},{id: "MN", name: "Minnesota"},{id: "MS", name: "Mississippi"},{id: "MO", name: "Missouri"},{id: "MT", name: "Montana"},{id: "NE", name: "Nebraska"},{id: "NV", name: "Nevada"},{id: "NH", name: "New Hampshire"},{id: "NJ", name: "New Jersey"},{id: "NM", name: "New Mexico"},{id: "NY", name: "New York"},{id: "NC", name: "North Carolina"},{id: "ND", name: "North Dakota"},{id: "OH", name: "Ohio"},{id: "OK", name: "Oklahoma"},{id: "OR", name: "Oregon"},{id: "PA", name: "Pennsylvania"},{id: "RI", name: "Rhode Island"},{id: "SC", name: "South Carolina"},{id: "SD", name: "South Dakota"},{id: "TN", name: "Tennessee"},{id: "TX", name: "Texas"},{id: "UT", name: "Utah"},{id: "VT", name: "Vermont"},{id: "VA", name: "Virginia"},{id: "WA", name: "Washington"},{id: "WV", name: "West Virginia"},{id: "WI", name: "Wisconsin"},{id: "WY", name: "Wyoming"},{id: "NL", name: "Newfoundland and Labrador"},{id: "PE", name: "Prince Edward Island"},{id: "NS", name: "Nova Scotia"},{id: "NB", name: "New Brunswick"},{id: "QC", name: "Quebec"},{id: "ON", name: "Ontario"},{id: "MB", name: "Manitoba"},{id: "SK", name: "Saskatchewan"},{id: "AB", name: "Alberta"},{id: "BC", name: "British Columbia"},{id: "YT", name: "Yukon"},{id: "NT", name: "Northwest Territories"},{id: "NU", name: "Nunavut"}]
  const fileFormatList = [{id: 5, name: "Fixed Format"},{id: 6, name: "CSV"}]
  const memberGroupList = [{id: 1,name: "Crossovers"}, {id: 2,name: "East Coast"}, {id: 3,name: "West Coast"}, {id: 4,name: "A La Carte"}]
  //const [view, setView] = useContext(useViewContext());
  let [state, setState]= useState({
    open: false,
    memberGroup: "All",
    memberId: "",
    name: "",
    contactFirstName: "",
    contactLastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    memberGroupId: 1,
    fileFormatId: 5,
    action: "add"
  });
  let [data, setData] = useState(null);
  let [refresh, setRefresh] = useState({
    refresh: false
  });
  useEffect(() => {
    async function getMembers(memberGroup)
    {

      try {
        // set loading to true before calling API
        const data = await AdminService.getMembers(memberGroup);
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
        getMembers("All");
  
    }, []);

async function refreshMembers(memberGroup){
    try {
        // set loading to true before calling API
        const data = await AdminService.getMembers(memberGroup);
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
}
async function handleChange(event) {

        let newValue = event.target.value;
        let memberGroup = state.memberGroup;

        switch(event.target.id)
        {
            case "#memberGroup":
                memberGroup = newValue;
                setState( prevState =>
                (
                    {
                        ...prevState,
                        memberGroup: newValue
                    }
                )
                );
                refreshMembers(memberGroup);
                break;
            case "#MemberId":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        memberId: newValue
                    }
                )
                );
              break;
            case "#Name":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        name: newValue
                    }
                )
                );
              break;
            case "#FirstName":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        contactFirstName: newValue
                    }
                )
                );
              break;
            case "#LastName":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        contactLastName: newValue
                    }
                )
                );
              break;
            case "#Email":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        email: newValue
                    }
                )
                );
              break;
            case "#Phone":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        phoneNumber: newValue
                    }
                )
                );
              break;
            case "#Address":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        address: newValue
                    }
                )
                );
              break;
            case "#City":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        city: newValue
                    }
                )
                );
              break;
            case "#State":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        state: newValue
                    }
                )
                );
              break;
            case "#Zip":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        zip: newValue
                    }
                )
                );
              break;
            case "#Group":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        memberGroupId: newValue
                    }
                )
                );
              break;
            case "#FileFormat":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        fileFormatId: newValue
                    }
                )
                );
              break;
            default:
                break;
        }

        

    }

  /** Handle opening and closing the Modal window */
  function toggle(e) {
   
    setState( prevState =>
      ({
      ...prevState,
      open: !state.open
      }
      ) 
      );
      
  }

  function addNewMember(e) {
    setState( prevState =>
      ({
      ...prevState,
      open: false,
      memberGroup: "All",
      memberId: "",
      name: "",
      contactFirstName: "",
      contactLastName: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
      memberGroupId: 1,
      fileFormatId: 5,
      action: "add"
      }
      ) 
      );
    toggle();
  }

  async function addMember(e) {

    if (state.action == "add")
        await AdminService.addMember(state);
    else
        await AdminService.updateMember(state);

    try {
      // set loading to true before calling API
      const data = await AdminService.getAllMembers();
      setData(data);
      // alert(JSON.stringify(data));
    } catch (error) {
      // add error handling here
      console.log(error);
    }

    toggle();

  }


  const DeleteButton = (props) =>
    {
      
        return(
          <div>
          <button type="button" class="btn btn-link"  onClick={() => DeleteMember(props.member_id)} >Delete</button>
          </div>
        )
    } 
  const UpdateButton = (props) =>
    {
      
        return(
          <div>
          <button type="button" class="btn btn-link" onClick={() => UpdateMember(props.member_id)} >Update</button>
          </div>
        )
    } 


  async function DeleteMember(member_id){

    let result = await AdminService.DeleteMember(member_id)
    refreshMembers(state.memberGroup)

  }

  async function UpdateMember(member_id){
    
    let result = await AdminService.getMemberbyId(member_id)
    //alert(JSON.stringify(result[0]))
    setState( prevState =>
      ({
        ...prevState,
        memberId: member_id,
        name: result[0].name,
        contactFirstName: result[0].contact_first_name,
        contactLastName: result[0].contact_last_name,
        email: result[0].email,
        address: result[0].address,
        city: result[0].city,
        state: result[0].state,
        zip: result[0].postal_code,
        phoneNumber: result[0].phone_number,
        memberGroupId: result[0].member_group_id,
        fileFormatId: result[0].file_format_id,
        action: "update"
      
      }
      ) 
      );
      toggle();
    
    //refreshMembers(state.memberGroup)

  }
  const MemberGroupSelect = (props) =>
    {
       // alert(JSON.stringify(props))
        if (props.memberGroupOption.id == props.memberGroupSelected)
          return <option selected value={props.memberGroupOption.id}>{props.memberGroupOption.name }</option>

        return <option value={props.memberGroupOption.id}>{props.memberGroupOption.name }</option>
      
    }

  const FileFormatSelect = (props) =>
    {
        // alert(JSON.stringify(props))
        if (props.fileFormatOption.id == props.fileFormatSelected)
          return <option selected value={props.fileFormatOption.id}>{props.fileFormatOption.name }</option>

        return <option value={props.fileFormatOption.id}>{props.fileFormatOption.name }</option>
      
    }
  
  const StateSelect = (props) =>
    {
        // alert(JSON.stringify(props))
        if (props.stateOption.id == props.stateSelected)
          return <option selected value={props.stateOption.id}>{props.stateOption.name }</option>

        return <option value={props.stateOption.id}>{props.stateOption.name }</option>
      
    }

  const Rows = (props) => {
    
    const {member_id,name,firstName, lastName,email,phone,member_group, file_format} = props
    //let accessDate = access_date.toString().substring(4,6) + '/' + access_date.toString().substring(6,8) + '/' + access_date.toString().substring(2,4);

    return ( <tr>
      <td>{member_id}</td>
      <td>{name}</td>
      <td>{firstName + ' ' + lastName}</td>
      <td>{email}</td>
      <td>{phone}</td>
      <td>{member_group}</td>
      <td>{file_format}</td>
      <td><UpdateButton member_id={member_id} >Update</UpdateButton></td>
      <td><DeleteButton member_id={member_id} >Delete</DeleteButton></td>
    </tr>
    )
  }
 


  const Table = (props) => {
    
 
    return ( <Row className="px-5"><table className="table table-sm">
              <thead className="table-light">
                <tr>
                <th scope="col">Member Id</th>
                <th scope="col">Name</th>
                <th scope="col">Contact Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Member Group</th>
                <th scope="col">File Format</th>
                <th scope="col">Update</th>
                <th scope="col">Delete</th>
                </tr>
              </thead>
            <tbody> 
              
              {data.map((data) => (
                <Rows key={data.member_id} member_id={data.member_id} name={data.name} firstName={data.contact_first_name} lastName={data.contact_last_name} email={data.email} phone={data.phone_number} member_group={data.member_group} file_format={data.file_format} /> 
              ))}

            </tbody>
            </table>
            </Row>)
  }


  if (!data) return (
    <ProtectedRoute>
        <Container>
      
            <Card>
            <CardHeader className="border-bottom">
            <h5>
                Member Managment
            </h5>
            </CardHeader>
            <span>Data not available</span>
            </Card>
        </Container>

    </ProtectedRoute>
    
  );

  return (
    
    <ProtectedRoute>
      <Container>
      
      <Card>
      <CardHeader className="border-bottom">
        <h5>
          Member Managment
        </h5>
      </CardHeader>
        <Row className="px-4">
            <Col lg="9" className="py-3 px-4">
            <div className="d-flex justify-content-left bd-highlight">
              <Button size="sm" className="mb-2 mr-1" onClick={addNewMember.bind(this)}>Add Member</Button>
            </div>
            </Col>
            <Col>
            <label htmlFor="#memberGroup">Member Group</label>
    
            <FormSelect className="mb-2 mr-1" id="#memberGroup" onChange={handleChange.bind(this)} >
                <option value="All">All</option>
                <option value="Crossovers">Crossovers</option>
                <option value="West Coast">West Coast</option>
                <option value="A La Carte">A La Carte</option>
            </FormSelect>  
            </Col>
            
        </Row> 
      <Table data={props}/>
      <Row>
      
        <Modal className="modal-lg" open={state.open}  centered={true}>
          <ModalHeader>Add Member</ModalHeader>
          <ModalBody>
            <Card className="mb-4">                     
              <CardBody className="p-1">
                  <Row noGutters className="page-header px-0 py-4">
                     <Col className="m-2">
                      <Row>

                        <Col>
                          <FormGroup>
                          <label htmlFor="#MemberId">Member Id</label>
                            <FormInput id="#MemberId" placeholder="Member Id" required value={state.memberId} onChange={handleChange.bind(this)} />  
                          <label htmlFor="#Name">Name</label>
                              <FormInput id="#Name" placeholder="Name" required value={state.name} onChange={handleChange.bind(this)} />
                          <label htmlFor="#FirstName">Contact First Name</label>
                          <FormInput id="#FirstName" placeholder="Contact First Name" required value={state.contactFirstName} onChange={handleChange.bind(this)} /> 
                          <label htmlFor="#LastName">Contact Last Name</label>
                              <FormInput id="#LastName" placeholder="Contact Last Name" required value={state.contactLastName} onChange={handleChange.bind(this)} />
                          <label htmlFor="#Group">Member Group</label>
                          <FormSelect className="mb-2 mr-1" id="#Group" onChange={handleChange.bind(this)} >
                              <option>-- Choose One --</option>
                              {memberGroupList.map((memberGroupOption) => (
                                      <MemberGroupSelect memberGroupOption={memberGroupOption} memberGroupSelected={state.memberGroupId} />
                                  ))}
                          </FormSelect>  
                          <label htmlFor="#FileFormat">File Format</label>
                          <FormSelect className="mb-2 mr-1" id="#FileFormat" onChange={handleChange.bind(this)} >
                              <option>-- Choose One --</option>
                              {fileFormatList.map((fileFormatOption) => (
                                      <FileFormatSelect fileFormatOption={fileFormatOption} fileFormatSelected={state.fileFormatId} />
                                  ))}
                          </FormSelect>   
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label htmlFor="#Address">Address</label>
                              <FormInput id="#Address" placeholder="Address" required value={state.address} onChange={handleChange.bind(this)} />  
                            <label htmlFor="#City">City</label>
                              <FormInput id="#City" placeholder="City" required value={state.city} onChange={handleChange.bind(this)} />
                              <label htmlFor="#State">Customer State</label>
                                      <FormSelect id="#State" onChange={handleChange.bind(this)} >
                                        <option value=''>--Select--</option>
                                        {stateList.map((stateOption) => (
                                            <StateSelect stateOption={stateOption} stateSelected={state.state} />
                                        ))}
                                      </FormSelect>                             
                              <label htmlFor="#Zip">Zip</label>
                            <FormInput id="#Zip" placeholder="Zip" required value={state.zip} onChange={handleChange.bind(this)} />
                            
                            <label htmlFor="#Phone">Phone</label>
                              <FormInput id="#Phone" placeholder="Phone" required value={state.phoneNumber} onChange={handleChange.bind(this)} />  
                            <label htmlFor="#Email">Email</label>
                              <FormInput id="#Email" placeholder="Email" required value={state.email} onChange={handleChange.bind(this)} />    
                          </FormGroup>
                        </Col>
                        <Row>
                          <div class="d-flex justify-content-center">
                                    
                                    <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={addMember.bind(this)}>Save</Button>
                                    <label>&nbsp;&nbsp;</label>
                                    <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={toggle}>Cancel</Button>
                                  
                            </div>
                        </Row>
                      </Row>
                      </Col>
                  </Row>
                </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        
      </Row>
      </Card>
      </Container>
      </ProtectedRoute>
  )
}

